<template>
  <!-- 作品详情配置列表 -->
  <body-layout v-if="type == 'GLOBAL'" :style="backCss">
    <div class="actions flex" slot="actions" style="min-height:32px">
      <a-button
        type="danger"
        class="mr-3"
        :loading="loading"
        @click="batchDel"
        icon="delete"
        v-if="selectedRowKeys.length && parent.isTongChou"
        v-has="domain == 'PRODUCT_DETAIL' ? 'info:button:delete' : 'coefficientPar:button:delete'"
        >删除</a-button
      >
      <a-button
        type="default"
        class="mr-3"
        :loading="loading"
        @click="batchCopy"
        icon="copy"
        v-if="selectedRowKeys.length && parent.isTongChou"
        v-has="domain == 'PRODUCT_DETAIL' ? 'info:button:edit' : 'coefficientPar:button:edit'"
        >创建副本</a-button
      >
      <a-button
        v-if="parent.isTongChou"
        type="primary"
        :loading="loading"
        @click="openAddForm"
        icon="plus"
        v-has="domain == 'PRODUCT_DETAIL' ? 'info:button:add' : 'coefficientPar:button:add'"
        >添加</a-button
      >
    </div>
    <div>
      <a-table
        ref="table"
        style="border-top:1px solid rgb(232,232,232)"
        size="middle"
        rowKey="id"
        :columns="domain ? columnsNew : columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 90 }"
        @change="handleTableChange"
      >
        <template slot="type" slot-scope="text, record">
          <span v-if="record.cardList">{{ record.cardList[0] | typeFilter }}</span>
        </template>
        <span slot="canChapter" slot-scope="text, record">
          {{ record.canChapter ? '是' : '否' }}
        </span>
        <span slot="valueRequired" slot-scope="text, record">
          {{ record.cardList[0].dataList[0].valueRequired == 'YES' ? '是' : '否' }}
        </span>
        <span @click.stop slot="canSync" slot-scope="text, record">
          <!-- <span v-if="record.cardList">{{ record.canSync || record.canSync === null ? '允许' : '不允许' }}</span> -->
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-if="record.cardList"
            :disabled="!isAdmin"
            @change="canSyncChange(record)"
            v-model="record.canSync"
          />
        </span>
      </a-table>
    </div>
    <product-modal :type="type" :open="drawerShow" :cur-row="curRow" :isForm="isNewForm" @closed="drawerClosed" />
  </body-layout>
  <div v-else :style="backCss">
    <div class="actions flex jc-end mb-4" slot="actions" style="min-height:32px">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        :loading="loading"
        icon="delete"
        v-if="selectedRowKeys.length && parent.isTongChou"
        v-has="
          type != 'PLATFORM'
            ? domain == 'PRODUCT_DETAIL'
              ? 'info:button:delete'
              : 'coefficientPar:button:delete'
            : domain == 'PRODUCT_DETAIL'
            ? 'palatform:button:delete'
            : 'palatform:coefficientPar:button:delete'
        "
        >删除</a-button
      >
      <!--       <a-button
        type="default"
        class="mr-3"
        :loading="loading"
        @click="batchCopy"
        v-if="selectedRowKeys.length"
        v-has="type!='PLATFORM'?domain=='PRODUCT_DETAIL'?'info:button:edit':'coefficientPar:button:edit':domain=='PRODUCT_DETAIL'?'palatform:button:edit':'palatform:coefficientPar:button:edit'"
      >创建副本</a-button> -->
      <a-button
        type="primary"
        :loading="loading"
        @click="openAddForm"
        icon="plus"
        v-if="parent.isTongChou"
        v-has="
          type != 'PLATFORM'
            ? domain == 'PRODUCT_DETAIL'
              ? 'info:button:add'
              : 'coefficientPar:button:add'
            : domain == 'PRODUCT_DETAIL'
            ? 'palatform:button:add'
            : 'palatform:coefficientPar:button:add'
        "
        >添加</a-button
      >
    </div>
    <div>
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="domain ? columnsNew : columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 90 }"
        @change="handleTableChange"
      >
        <template slot="type" slot-scope="text, record">
          <span v-if="record.cardList">{{ record.cardList[0] | typeFilter }}</span>
        </template>
        <span slot="canChapter" slot-scope="text, record">
          {{ record.canChapter ? '是' : '否' }}
        </span>
        <span slot="valueRequired" slot-scope="text, record">
          {{ record.cardList[0].dataList[0].valueRequired == 'YES' ? '是' : '否' }}
        </span>
        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">{{ one.departName }}</a-tag>
        </span>
        <span slot="positionNames" slot-scope="text, record">
          <a-tag v-for="one in record.positions" :key="one.positionId"
            >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
          >
        </span>
        <span @click.stop slot="canSync" slot-scope="text, record">
          <!-- <span v-if="record.cardList">{{ record.canSync || record.canSync === null ? '允许' : '不允许' }}</span> -->
          <a-switch
            checked-children="是"
            :disabled="!isAdmin"
            un-checked-children="否"
            v-if="record.cardList"
            @change="canSyncChange(record)"
            v-model="record.canSync"
          />
        </span>
      </a-table>
    </div>
    <product-modal-edit
      v-if="!isAdd"
      :isSync="true"
      :type="type"
      :relationId="parent.id + ''"
      :open="drawerShow"
      :domain="domain"
      :isTongChou="parent.isTongChou"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    />
    <product-modal
      v-else
      :type="type"
      :dataSource="dataSource"
      :relationId="parent.id + ''"
      :platformId="parent.platformId + ''"
      :open="drawerShow"
      :domain="domain"
      :departId="parent.departmentId"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    />
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import productModal from './product-modal.vue'
import productModalEdit from '@/views/system/modules/product/product-modal.vue'
import { postAction } from '@/api/manage'
import { USER_INFO } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  mixins: [JeecgListMixin],
  components: {
    productModal,
    productModalEdit
  },
  data() {
    return {
      filters: {
        type: this.type,
        relationId: this.parent.id,
        domain: this.domain
      },
      color: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 100
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 170
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 170
        },
        {
          title: '适用到章节',
          dataIndex: 'canChapter',
          scopedSlots: { customRender: 'canChapter' },
          width: 170
        },
        {
          title: () => {
            return this.isShow ? (
              <div style="display:flex;align-items:center">
                <a-switch
                  style="margin-right:10px"
                  checked-children="同步"
                  un-checked-children="同步"
                  disabled={!this.isAdmin}
                  default-checked={this.allChecked}
                  onChange={this.canSyncChangeAll}
                />
              </div>
            ) : (
              ''
            )
          },
          dataIndex: 'canSync',
          scopedSlots: { customRender: 'canSync' },
          width: 170
        },
        {
          title: '是否必填',
          dataIndex: 'valueRequired',
          scopedSlots: { customRender: 'valueRequired' },
          width: 170
        },
        /*         {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 180
        }, */
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions'
        }
      ],
      columnsNew: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 100
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 170
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 170
        },
        {
          title: () => {
            return this.isShow ? (
              <div style="display:flex;align-items:center">
                <a-switch
                  style="margin-right:10px"
                  checked-children="同步"
                  un-checked-children="同步"
                  default-checked={this.allChecked}
                  disabled={!this.isAdmin}
                  onChange={this.canSyncChangeAll}
                />
              </div>
            ) : (
              ''
            )
          },
          dataIndex: 'canSync',
          scopedSlots: { customRender: 'canSync' },
          width: 170
        },
        {
          title: '是否必填',
          dataIndex: 'valueRequired',
          scopedSlots: { customRender: 'valueRequired' },
          width: 170
        },
        /*         {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 180
        }, */
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions'
        }
      ],
      allChecked: true,
      isShow: true,
      dataSource: [],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: {
          path: '/productDetail/page'
        },
        copyBatch: 'productDetail/copy',
        delete: '/fonts/delete',
        deleteBatch: '/productDetail/'
      },
      isAdmin:false,
    }
  },
  filters: {
    typeFilter(data) {
      const typeMap = {
        DYNAMIC_FORM: `动态表单${
          data.dataList
            ? data.dataList[0].type == 'INPUT'
              ? '-文本录入'
              : data.dataList[0].type == 'SELECT'
              ? '-下拉选项'
              : data.dataList[0].type == 'TAG'
              ? '-标签'
              : ''
            : ''
        }`,
        INPUT: '单行文本',
        INPUT_NUMBER: '数字输入框',
        TEXTAREA: '多行文本',
        RADIO: '单项选择',
        SELECT: '下拉选择',
        TAG: '标签'
      }
      return typeMap[data.type]
    }
  },
  props: {
    type: {
      type: String,
      default: () => 'GLOBAL'
    },
    title: {
      type: String,
      default: () => '新建'
    },
    parent: {
      type: Object,
      default: () => {}
    },
    domain: {
      type: String,
      default: () => 'PRODUCT_DETAIL'
    }
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 500)
    let userInfo = Vue.ls.get(USER_INFO)
    if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) {
      this.isAdmin = true
    }
  },
  computed: {
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': color
      }
    }
  },
  watch: {
    dataSource: {
      handler: function(n, o) {
        this.isShow = false
        this.allChecked = this.dataSource.every(item => {
          return item.canSync
        })
        this.$nextTick(() => {
          this.isShow = true
        })
      },
      deep: true
    }
  },
  methods: {
    async canSyncChange(record) {
      this.loading = true
      const res = await postAction('/productDetail/batch', { list: [record], editType: 'PRODUCT' })
      if (res.success) {
        this.$message.success('保存成功')
        this.loadData()
      } else {
        this.$message.error(res.msg || res.message)
        this.loading = false
      }
    },
    async canSyncChangeAll(e) {
      this.dataSource.forEach(item => {
        item.canSync = e
      })
      this.loading = true
      const res = await postAction('/productDetail/batch', { list: this.dataSource, editType: 'PRODUCT' })
      if (res.success) {
        this.$message.success('保存成功')
        this.loadData()
      } else {
        this.$message.error(res.msg || res.message)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-row:hover {
  .ant-table-row-cell-break-word {
    background-color: var(--back-color) !important;
  }
  td {
    background-color: var(--back-color) !important;
  }
}
</style>
