var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type == "GLOBAL"
    ? _c(
        "body-layout",
        { style: _vm.backCss },
        [
          _c(
            "div",
            {
              staticClass: "actions flex",
              staticStyle: { "min-height": "32px" },
              attrs: { slot: "actions" },
              slot: "actions",
            },
            [
              _vm.selectedRowKeys.length && _vm.parent.isTongChou
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value:
                            _vm.domain == "PRODUCT_DETAIL"
                              ? "info:button:delete"
                              : "coefficientPar:button:delete",
                          expression:
                            "domain == 'PRODUCT_DETAIL' ? 'info:button:delete' : 'coefficientPar:button:delete'",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: {
                        type: "danger",
                        loading: _vm.loading,
                        icon: "delete",
                      },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _vm.selectedRowKeys.length && _vm.parent.isTongChou
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value:
                            _vm.domain == "PRODUCT_DETAIL"
                              ? "info:button:edit"
                              : "coefficientPar:button:edit",
                          expression:
                            "domain == 'PRODUCT_DETAIL' ? 'info:button:edit' : 'coefficientPar:button:edit'",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: {
                        type: "default",
                        loading: _vm.loading,
                        icon: "copy",
                      },
                      on: { click: _vm.batchCopy },
                    },
                    [_vm._v("创建副本")]
                  )
                : _vm._e(),
              _vm.parent.isTongChou
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value:
                            _vm.domain == "PRODUCT_DETAIL"
                              ? "info:button:add"
                              : "coefficientPar:button:add",
                          expression:
                            "domain == 'PRODUCT_DETAIL' ? 'info:button:add' : 'coefficientPar:button:add'",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        icon: "plus",
                      },
                      on: { click: _vm.openAddForm },
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("a-table", {
                ref: "table",
                staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
                attrs: {
                  size: "middle",
                  rowKey: "id",
                  columns: _vm.domain ? _vm.columnsNew : _vm.columns,
                  dataSource: _vm.dataSource,
                  customRow: _vm.clickRow,
                  pagination: _vm.ipagination,
                  loading: _vm.loading,
                  scroll: { x: "max-content", y: "calc(100vh - 353px)" },
                  rowSelection: {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                    columnWidth: 90,
                  },
                },
                on: { change: _vm.handleTableChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "type",
                      fn: function (text, record) {
                        return [
                          record.cardList
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("typeFilter")(record.cardList[0])
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "canChapter",
                      fn: function (text, record) {
                        return _c("span", {}, [
                          _vm._v(
                            "\n        " +
                              _vm._s(record.canChapter ? "是" : "否") +
                              "\n      "
                          ),
                        ])
                      },
                    },
                    {
                      key: "valueRequired",
                      fn: function (text, record) {
                        return _c("span", {}, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                record.cardList[0].dataList[0].valueRequired ==
                                  "YES"
                                  ? "是"
                                  : "否"
                              ) +
                              "\n      "
                          ),
                        ])
                      },
                    },
                    {
                      key: "canSync",
                      fn: function (text, record) {
                        return _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            record.cardList
                              ? _c("a-switch", {
                                  attrs: {
                                    "checked-children": "是",
                                    "un-checked-children": "否",
                                    disabled: !_vm.isAdmin,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.canSyncChange(record)
                                    },
                                  },
                                  model: {
                                    value: record.canSync,
                                    callback: function ($$v) {
                                      _vm.$set(record, "canSync", $$v)
                                    },
                                    expression: "record.canSync",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  3242108110
                ),
              }),
            ],
            1
          ),
          _c("product-modal", {
            attrs: {
              type: _vm.type,
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
            },
            on: { closed: _vm.drawerClosed },
          }),
        ],
        1
      )
    : _c(
        "div",
        { style: _vm.backCss },
        [
          _c(
            "div",
            {
              staticClass: "actions flex jc-end mb-4",
              staticStyle: { "min-height": "32px" },
              attrs: { slot: "actions" },
              slot: "actions",
            },
            [
              _vm.selectedRowKeys.length && _vm.parent.isTongChou
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value:
                            _vm.type != "PLATFORM"
                              ? _vm.domain == "PRODUCT_DETAIL"
                                ? "info:button:delete"
                                : "coefficientPar:button:delete"
                              : _vm.domain == "PRODUCT_DETAIL"
                              ? "palatform:button:delete"
                              : "palatform:coefficientPar:button:delete",
                          expression:
                            "\n        type != 'PLATFORM'\n          ? domain == 'PRODUCT_DETAIL'\n            ? 'info:button:delete'\n            : 'coefficientPar:button:delete'\n          : domain == 'PRODUCT_DETAIL'\n          ? 'palatform:button:delete'\n          : 'palatform:coefficientPar:button:delete'\n      ",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: {
                        type: "danger",
                        loading: _vm.loading,
                        icon: "delete",
                      },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _vm.parent.isTongChou
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value:
                            _vm.type != "PLATFORM"
                              ? _vm.domain == "PRODUCT_DETAIL"
                                ? "info:button:add"
                                : "coefficientPar:button:add"
                              : _vm.domain == "PRODUCT_DETAIL"
                              ? "palatform:button:add"
                              : "palatform:coefficientPar:button:add",
                          expression:
                            "\n        type != 'PLATFORM'\n          ? domain == 'PRODUCT_DETAIL'\n            ? 'info:button:add'\n            : 'coefficientPar:button:add'\n          : domain == 'PRODUCT_DETAIL'\n          ? 'palatform:button:add'\n          : 'palatform:coefficientPar:button:add'\n      ",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        icon: "plus",
                      },
                      on: { click: _vm.openAddForm },
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("a-table", {
                ref: "table",
                staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
                attrs: {
                  size: "middle",
                  rowKey: "id",
                  columns: _vm.domain ? _vm.columnsNew : _vm.columns,
                  dataSource: _vm.dataSource,
                  customRow: _vm.clickRow,
                  pagination: _vm.ipagination,
                  loading: _vm.loading,
                  scroll: { x: "max-content", y: "calc(100vh - 353px)" },
                  rowSelection: {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                    columnWidth: 90,
                  },
                },
                on: { change: _vm.handleTableChange },
                scopedSlots: _vm._u([
                  {
                    key: "type",
                    fn: function (text, record) {
                      return [
                        record.cardList
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("typeFilter")(record.cardList[0]))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "canChapter",
                    fn: function (text, record) {
                      return _c("span", {}, [
                        _vm._v(
                          "\n        " +
                            _vm._s(record.canChapter ? "是" : "否") +
                            "\n      "
                        ),
                      ])
                    },
                  },
                  {
                    key: "valueRequired",
                    fn: function (text, record) {
                      return _c("span", {}, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              record.cardList[0].dataList[0].valueRequired ==
                                "YES"
                                ? "是"
                                : "否"
                            ) +
                            "\n      "
                        ),
                      ])
                    },
                  },
                  {
                    key: "departmentNames",
                    fn: function (text, record) {
                      return _c(
                        "span",
                        {},
                        _vm._l(record.departments, function (one) {
                          return _c("a-tag", { key: one.departId }, [
                            _vm._v(_vm._s(one.departName)),
                          ])
                        }),
                        1
                      )
                    },
                  },
                  {
                    key: "positionNames",
                    fn: function (text, record) {
                      return _c(
                        "span",
                        {},
                        _vm._l(record.positions, function (one) {
                          return _c("a-tag", { key: one.positionId }, [
                            _vm._v(
                              _vm._s(one.positionName + "-") +
                                _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                            ),
                          ])
                        }),
                        1
                      )
                    },
                  },
                  {
                    key: "canSync",
                    fn: function (text, record) {
                      return _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          record.cardList
                            ? _c("a-switch", {
                                attrs: {
                                  "checked-children": "是",
                                  disabled: !_vm.isAdmin,
                                  "un-checked-children": "否",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.canSyncChange(record)
                                  },
                                },
                                model: {
                                  value: record.canSync,
                                  callback: function ($$v) {
                                    _vm.$set(record, "canSync", $$v)
                                  },
                                  expression: "record.canSync",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          !_vm.isAdd
            ? _c("product-modal-edit", {
                attrs: {
                  isSync: true,
                  type: _vm.type,
                  relationId: _vm.parent.id + "",
                  open: _vm.drawerShow,
                  domain: _vm.domain,
                  isTongChou: _vm.parent.isTongChou,
                  "cur-row": _vm.curRow,
                  isForm: _vm.isNewForm,
                },
                on: { closed: _vm.drawerClosed },
              })
            : _c("product-modal", {
                attrs: {
                  type: _vm.type,
                  dataSource: _vm.dataSource,
                  relationId: _vm.parent.id + "",
                  platformId: _vm.parent.platformId + "",
                  open: _vm.drawerShow,
                  domain: _vm.domain,
                  departId: _vm.parent.departmentId,
                  "cur-row": _vm.curRow,
                  isForm: _vm.isNewForm,
                },
                on: { closed: _vm.drawerClosed },
              }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }